/**
 * Query data field Names
 * @type {Readonly<{CategoryId: string, TypeId: string, Owner: string, CreatedDateTo: string, StateId: string, Creator: string, Name: string, Extension: string, Pages: string, Fields: string, Version: string, CreatedDateFrom: string, ModifiedDateTo: string, Id: string, ModifiedDateFrom: string}>}
 */
const fieldName = Object.freeze({
  Id: "Id",
  Name: "Name",
  Owner: "Owner",
  Creator: "Creator",
  CreatedDateFrom: "CreatedDateFrom",
  CreatedDateTo: "CreatedDateTo",
  ModifiedDateFrom: "ModifiedDateFrom",
  ModifiedDateTo: "ModifiedDateTo",
  CategoryId: "CategoryId",
  TypeId: "TypeId",
  StateId: "StateId",
  Extension: "Extension",
  Version: "Version",
  Pages: "Pages",
  Fields: "Fields"
});

/**
 * Create Query Field Model
 * @param {boolean} includeAnnotations
 * @param {boolean} includeRedaction
 * @param {boolean} burnIntoImage
 * @param {boolean}  burnRedaction
 * @param {boolean} maintainColor
 * @return {{IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean }}
 */
const AnnotationOptionQueryModel = (
  includeAnnotations = false,
  includeRedaction = true,
  burnIntoImage = false,
  maintainColor = false,
  burnRedaction = true
) => {
  return {
    IncludeAnnotations: includeAnnotations,
    IncludeRedaction: includeRedaction,
    BurnIntoImage: burnIntoImage,
    EmbedIntoImage: !burnIntoImage, // TODO: TO BE REMOVED, JUST keep it to be backwards comp...
    BurnRedaction: burnRedaction,
    MaintainColor: maintainColor
  };
};

export { fieldName, AnnotationOptionQueryModel };
