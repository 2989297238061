// utils
import { createBlob } from "@/utils";

// design
import { iconDownload } from "@/design/icon/iconConst";

// model
import { fieldDownloadType } from "@/model/document/documentModel";
import { AnnotationOptionQueryModel } from "@/model/query/queryModel";
import {
  downloadFileMode,
  extensionName,
  isPdfExtension
} from "@/model/record/fileModel";

// services
import { notImplementedMethod } from "@/services/error/errorMessages";
import { emailFile, saveFile } from "@/services/dom/fileSystemAccessService";

import { createPrintFileAnchor } from "@/services/dom/domService";

// vuex
import { createNamespacedHelpers } from "vuex";
// mixins
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";

import {
  actionResultType,
  createActionResultAbort,
  //actionResultType,
  createActionResultError,
  createActionResultSuccess
} from "@/model/action/actionModel";
import {
  findRecordOperation,
  recordOperation,
  recordType
} from "@/model/record/recordModel";
import {
  fullRecordVersionName,
  isAncestorMeeting,
  isCheckedOutByPrincipal,
  IsCheckedOutForDigitalSigningByPrinciple,
  isCompoundDocument,
  localFileExists,
  localFileFirstOrDefaultExtension
} from "@/services/record/recordService";
import { principalMixin } from "@/mixins/shared/user/principalMixin";

const { mapActions } = createNamespacedHelpers("document");

export const downloadableMixin = {
  mixins: [baseComponentMixin, principalMixin],
  data() {
    return {
      iconDownload: iconDownload
    };
  },
  methods: {
    ...mapActions({
      downloadImageFileVersion: "downloadImageFileVersion",
      downloadImageFileVersionAsPdf: "downloadFileVersionAsPdf",
      downloadCompoundAsPdf: "downloadCompoundAsPdf",
      downloadFileVersion: "downloadFileVersion",
      downloadEmptyRecordWip: "downloadEmptyRecordWip",
      downloadEmptyRecordWipAsPdf: "downloadEmptyRecordWipAsPdf",
      downloadFileWip: "downloadFileWip",
      downloadFileWipAsPdf: "downloadFileWipAsPdf",
      downloadPdfFileVersion: "downloadPdfFileVersion",
      downloadFileEdit: "downloadFileEdit",
      downloadFileEditAsPdf: "downloadFileEditAsPdf",
      downloadFileExport: "downloadExportFileVersion",
      downloadFileExportAsPdf: "downloadExportFileVersionAsPdf"
    }),
    /**
     * preparePayload
     * @param {number} id
     * @param {number} version
     * @param {boolean} includeAnnotations
     * @param {boolean} includeRedaction
     * @param {boolean} burnIntoImage
     * @param {boolean} burnRedaction
     * @param {boolean} maintainColor
     * @return {any}
     */
    createFileDownloadPayload(
      id,
      version,
      includeAnnotations,
      includeRedaction,
      burnIntoImage,
      burnRedaction,
      maintainColor
    ) {
      const downloadOptions = AnnotationOptionQueryModel(
        includeAnnotations,
        includeRedaction,
        burnIntoImage,
        maintainColor,
        burnRedaction
      );

      return {
        id: id,
        version: version,
        downloadOptions: downloadOptions
      };
    },

    /**
     * create Compound Document Download Payload
     * @param {number|Number} id Compound Document id
     * @param {boolean} includeAnnotations
     * @param {boolean} includeRedaction
     * @param {boolean} burnIntoImage
     * @param {boolean} burnRedaction
     * @param {boolean} maintainColor
     * @return {{downloadOptions: {IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}, id}}
     */
    createCompoundDocumentDownloadPayload(
      id,
      includeAnnotations,
      includeRedaction,
      burnIntoImage,
      burnRedaction,
      maintainColor
    ) {
      const downloadOptions = AnnotationOptionQueryModel(
        includeAnnotations,
        includeRedaction,
        burnIntoImage,
        maintainColor,
        burnRedaction
      );

      return {
        id: id,
        downloadOptions: downloadOptions
      };
    },

    /**
     * Abstract Release File
     * @param {Number|number} id
     */
    releaseFile(id) {
      throw notImplementedMethod(`releaseFile() id: ${id}`);
    },

    /**
     * Abstract Check Out File
     * @param {Number|number} id
     */
    checkOutFile(id) {
      throw notImplementedMethod(`checkOutFile() id: ${id}`);
    },

    /**
     * Abstract Release CheckedOut File
     * @param {Number|number} id
     */
    releaseCheckedOutFile(id) {
      throw notImplementedMethod(`releaseCheckedOutFile() id: ${id}`);
    },

    /**
     * Abstract Release WIP File
     * @param {Number|number} id
     */
    releaseWIPFile(id) {
      throw notImplementedMethod(`releaseWIPFile() id: ${id}`);
    },

    /**
     * Abstract Release Record
     * @param {Number|number} id
     */
    releaseWIPRecord(id) {
      throw notImplementedMethod(`releaseFile() id: ${id}`);
    },

    /**
     * onDownloadFile
     * @param {{id:number, name:string, categoryId:number, extension:string, flags:number, hasImage:boolean, recordTypeId:number, recordType:string, creationDate:string, modificationDate:string, version:number, versionDate:string, pages:number, owner:string, createdBy:string, isSingleFile:boolean}} record file-record
     * @param {string} downloadType (Differentiate between original download and download as a pdf)
     * @param {{includeAnnotations: boolean, includeRedaction: boolean, burnIntoImage: boolean, burnRedaction: boolean, maintainColor: boolean}} downloadFileOption
     * @param {number} downloadMode
     * @param {Boolean} isEmail
     * @param {Boolean} isBatchDownload
     * @return {Promise<{type: string, message: string, outcome: string}>} action Result
     */
    async onDownloadFile(
      record,
      downloadType = fieldDownloadType.asItIs,
      downloadFileOption = undefined,
      downloadMode = downloadFileMode.default,
      isEmail = false,
      isBatchDownload = false
    ) {
      const isBatchedEmail = isEmail && isBatchDownload;
      try {
        const operation = findRecordOperation(recordOperation.Download);
        const action = `${operation?.label} ${
          record?.recordType
        }: '${fullRecordVersionName(record)}'`;

        const isWip = downloadMode === downloadFileMode.wip;
        const pdf = extensionName.pdf;
        const fileName = record?.name ?? "";

        const isCompound = isCompoundDocument(record);

        const response = await this.performDownload(
          record,
          downloadFileOption,
          downloadMode,
          downloadType
        );

        // Create a new blob
        const blob = createBlob(response.data);
        console.log(blob);

        console.log(`onDownloadFile.record:`, record);

        const isCheckedOutForSigning = IsCheckedOutForDigitalSigningByPrinciple(
          record,
          this.principal
        );

        // Set file extension
        const extension = isCompound
          ? pdf
          : downloadType === fieldDownloadType.asItIs
          ? isWip
            ? localFileFirstOrDefaultExtension(record)
            : isCheckedOutForSigning && downloadMode !== downloadFileMode.view
            ? record.checkOutExtension ?? record.extension
            : record?.extension ?? ""
          : pdf;

        console.log(`onDownloadFile.extension:`, extension);

        // Should be improved
        if (isBatchedEmail) {
          const fullFileName = fileName + "." + extension;
          return {
            result: createActionResultSuccess(action),
            blob: blob,
            fileName: fullFileName
          };
        }

        return (isEmail
          ? await emailFile(blob, fileName)
          : await saveFile(
              blob,
              fileName,
              extension,
              isCheckedOutForSigning,
              isBatchDownload
            )) === actionResultType.abort
          ? createActionResultAbort(action)
          : createActionResultSuccess(action);
      } catch (e) {
        if (isBatchedEmail) {
          return {
            result: createActionResultError(e?.toString()),
            blob: undefined,
            fileName: ""
          };
        }
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Create Payload for Compound
     * @param {number} id
     * @param {{includeAnnotations: boolean, includeRedaction: boolean, burnIntoImage: boolean, burnRedaction: boolean, maintainColor: boolean}} downloadFileOption
     * @return {{downloadOptions: {IncludeRedaction: boolean, EmbedIntoImage: boolean, IncludeAnnotations: boolean, BurnRedaction: boolean, MaintainColor: boolean, BurnIntoImage: boolean}, id}}
     */
    createCompoundDocumentPayload(id, downloadFileOption) {
      return this.createCompoundDocumentDownloadPayload(
        id,
        downloadFileOption?.includeAnnotations ?? false,
        downloadFileOption?.includeRedaction ?? false,
        downloadFileOption?.burnIntoImage ?? false,
        downloadFileOption?.burnRedaction ?? false,
        downloadFileOption?.maintainColor ?? false
      );
    },

    /**
     * Create Payload for File
     * @param {number} id
     * @param {number} version
     * @param {{includeAnnotations: boolean, includeRedaction: boolean, burnIntoImage: boolean, burnRedaction: boolean, maintainColor: boolean}} downloadFileOption
     * @return {*}
     */
    createFilePayload(id, version, downloadFileOption) {
      return this.createFileDownloadPayload(
        id,
        version,
        downloadFileOption?.includeAnnotations ?? false,
        downloadFileOption?.includeRedaction ?? false,
        downloadFileOption?.burnIntoImage ?? false,
        downloadFileOption?.burnRedaction ?? false,
        downloadFileOption?.maintainColor ?? false
      );
    },

    /**
     * Perform download based Download on file Mode
     * @param {number} downloadMode
     * @param {string} downloadType (Differentiate between original download and download as a pdf)
     * @param {Object} payload
     * @param {Number} recordTypeId
     * @return {Promise<*|string>}
     */
    async performDownloadFileMode(
      downloadMode,
      downloadType,
      payload,
      recordTypeId
    ) {
      switch (downloadMode) {
        case downloadFileMode.wip:
          return await this.downloadWipFile(
            downloadType,
            payload,
            recordTypeId
          );

        case downloadFileMode.viewerExport:
          return await this.downloadExportFile(downloadType, payload);

        case downloadFileMode.view:
          return await this.downloadViewFile(downloadType, payload);
      }
    },

    /**
     * Download Wip File
     * @param {string} downloadType (Differentiate between original download and download as a pdf)
     * @param {Object} payload
     * @param {Number} recordTypeId
     * @return {Promise<*>}
     */
    async downloadWipFile(downloadType, payload, recordTypeId) {
      return recordTypeId === recordType.FILE
        ? downloadType === fieldDownloadType.asItIs
          ? await this.downloadFileWip(payload)
          : await this.downloadFileWipAsPdf(payload)
        : downloadType === fieldDownloadType.asItIs
        ? await this.downloadEmptyRecordWip(payload)
        : await this.downloadEmptyRecordWipAsPdf(payload);
    },

    /**
     * Download Export File
     * @param {string} downloadType (Differentiate between original download and download as a pdf)
     * @param {Object} payload
     * @return {Promise<string>}
     */
    async downloadExportFile(downloadType, payload) {
      return downloadType === fieldDownloadType.asItIs
        ? await this.downloadFileExport(payload)
        : await this.downloadFileExportAsPdf(payload);
    },

    /**
     * Download View File
     * @param {string} downloadType (Differentiate between original download and download as a pdf)
     * @param {Object} payload
     * @return {Promise<string>}
     */
    async downloadViewFile(downloadType, payload) {
      return downloadType === fieldDownloadType.asItIs
        ? await this.downloadImageFileVersion(payload)
        : await this.downloadImageFileVersionAsPdf(payload);
    },

    /**
     * Download Edit File
     * @param {Object} record
     * @param {string} downloadType (Differentiate between original download and download as a pdf)
     * @param {Object} payload
     * @return {Promise<string>}
     */
    async downloadEditFile(record, downloadType, payload) {
      return localFileExists(this.record) //If local file doesn't exist, means file hasn't been opened in viewer (no editing performed), download view
        ? downloadType === fieldDownloadType.asItIs
          ? await this.downloadFileEdit(payload)
          : await this.downloadFileEditAsPdf(payload)
        : await this.downloadViewFile(downloadType, payload);
    },

    /**
     * Download Agenda File
     * Set download Options to null if Agenda Packet
     * @param {string} downloadType (Differentiate between original download and download as a pdf)
     * @param {Object} payload
     * @param {Boolean} isPdf
     * @return {Promise<string>}
     */
    async downloadAgendaFile(downloadType, payload, isPdf) {
      if (isPdf) {
        payload.downloadOptions = null; //if Pdf file (Agenda Packet), set options null to preserve bookmarks
        return await this.downloadImageFileVersion(payload);
      }
      return downloadType === fieldDownloadType.asItIs
        ? await this.downloadImageFileVersion(payload)
        : await this.downloadImageFileVersionAsPdf(payload);
    },

    /**
     * on Print File event handler
     * @param {{id:number, name:string, categoryId:number, extension:string, flags:number, hasImage:boolean, recordTypeId:number, recordType:string, creationDate:string, modificationDate:string, version:number, versionDate:string, pages:number, owner:string, createdBy:string, isSingleFile:boolean}} record
     * @param {string} downloadType
     * @param {{burnAnnotations: boolean, burnRedaction: boolean}} printFileOption
     * @param {number} downloadMode
     * @return {Promise<{type: string, message: string, outcome: string}>} action Result
     */
    async onPrintFile(
      record,
      downloadType = fieldDownloadType.asPdf,
      printFileOption = undefined,
      downloadMode = downloadFileMode.default
    ) {
      try {
        const operation = findRecordOperation(recordOperation.Print);
        const action = `${operation?.label} ${
          record?.recordType
        }: '${fullRecordVersionName(record)}'`;

        const option = {
          includeAnnotations: printFileOption?.burnAnnotations ?? false,
          includeRedaction: printFileOption?.burnRedaction ?? false,
          burnIntoImage: printFileOption?.burnAnnotations ?? false,
          burnRedaction: printFileOption?.burnRedaction ?? false,
          maintainColor: true
        };

        const response = await this.performDownload(
          record,
          option,
          downloadMode,
          downloadType
        );

        // Create a new blob
        const blob = createBlob(response.data);

        // Create download File anchor
        createPrintFileAnchor(blob);

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Perform Download/Print
     * @param {{id:number, name:string, categoryId:number, extension:string, flags:number, hasImage:boolean, recordTypeId:number, recordType:string, creationDate:string, modificationDate:string, version:number, versionDate:string, pages:number, owner:string, createdBy:string, isSingleFile:boolean}} record
     * @param {{includeAnnotations: boolean, includeRedaction: boolean, burnIntoImage: boolean, burnRedaction: boolean, maintainColor: boolean}} annotationModel
     * @param {number} downloadMode
     * @param {string} downloadType
     * @return {Promise<string|*>}
     */
    async performDownload(record, annotationModel, downloadMode, downloadType) {
      const isWip = downloadMode === downloadFileMode.wip;
      const id = record?.id ?? -1;
      const version = isWip ? 0 : record?.version ?? -1;
      const isCompound = isCompoundDocument(record);

      const payload = isCompound
        ? this.createCompoundDocumentPayload(id, annotationModel)
        : this.createFilePayload(id, version, annotationModel);

      console.log(`onDownloadFile() payload:`, payload);

      if (downloadMode !== downloadFileMode.default) {
        return await this.performDownloadFileMode(
          downloadMode,
          downloadType,
          payload,
          record?.recordTypeId ?? -1
        );
      } else {
        if (isCheckedOutByPrincipal(record, this.principal)) {
          return IsCheckedOutForDigitalSigningByPrinciple(
            record,
            this.principal
          )
            ? await this.downloadImageFileVersionAsPdf(payload)
            : await this.downloadEditFile(record, downloadType, payload);
        } else if (isAncestorMeeting(record))
          // For Agenda Packet to preserve bookmarks
          return await this.downloadAgendaFile(
            downloadType,
            payload,
            isPdfExtension(record?.extension)
          );
        else if (isCompound) return await this.downloadCompoundAsPdf(payload);
        else {
          return downloadType === fieldDownloadType.asItIs
            ? await this.downloadImageFileVersion(payload)
            : await this.downloadImageFileVersionAsPdf(payload);
        }
      }
    }

    /* async onEmailFile(
      record,
      downloadType = fieldDownloadType.asItIs,
      includeAnnotations = false,
      includeRedaction = false,
      burnIntoImage = false,
      burnRedaction = false,
      maintainColor = false
    ) {
      try {
        const operation = findRecordOperation(recordOperation.Download);
        const action = `${operation?.label} ${
          record?.recordType
        }: '${fullRecordVersionName(record)}'`;
        console.log(action);

        const id = record?.id ?? -1;
        const version = record?.version ?? -1;

        const pdf = extensionName.pdf;
        const fileName = record?.name ?? "";
        const isPdf = isPdfExtension(record?.extension);
        const isCompound = isCompoundDocument(record);

        const payload = isCompound
          ? this.createCompoundDocumentDownloadPayload(
              id,
              includeAnnotations,
              includeRedaction,
              burnIntoImage,
              burnRedaction,
              maintainColor
            )
          : this.createFileDownloadPayload(
              id,
              version,
              includeAnnotations,
              includeRedaction,
              burnIntoImage,
              burnRedaction,
              maintainColor
            );

        console.log(`onDownloadFile() payload:`, payload);

        // Download File
        const response = isCompound
          ? await this.downloadCompoundAsPdf(payload)
          : downloadType === fieldDownloadType.asItIs
          ? isPdf
            ? await this.downloadFileVersion({ id: id, version: version })
            : await this.downloadImageFileVersion(payload)
          : await this.downloadImageFileVersionAsPdf(payload);

        // Create a new blob
        const blob = createBlob(response.data);
        console.log(blob);

        // Get File Extension from the server
        // const extensions = response.data.type.split("/");
        // console.log(`response extensions:`, extensions);
        // does not work for office files! e.g. docx
        // const extension = extensions?.length ?? 0 > 0 ? extensions[1] : "";
        const extension = isCompound
          ? pdf
          : downloadType === fieldDownloadType.asItIs
          ? record?.extension ?? ""
          : pdf;

        console.log(`response extension:`, extension);

        //const emailName = fileName + ".eml";

        //createDownloadFileAnchor(blob, emailName);

        downloadEMLFile(blob, blob.type, fileName);

        // console.log(`fileReader.result:`, fileReader.result);

        //const fileData = fileReader.result.split(",")[1];

        // console.log(`fileData:`, fileData);

        // Create the mailto link with the attachment
        //return blob;

        /!*const attachment = `data:application/${extension};base64,${fileData}`;

        // console.log(`attachment:`, attachment);

        // Open the email client
        window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(
          attachment
        )}`;*!/

        //return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    }*/
  }
};
